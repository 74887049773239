module.exports = [{
      plugin: require('../../../node_modules/gatsby-plugin-firebase/gatsby-browser.js'),
      options: {"plugins":[],"features":{"auth":true,"database":false,"firestore":false,"storage":false,"messaging":false,"functions":false,"performance":false,"analytics":true},"credentials":{"apiKey":"AIzaSyD-4fa4bpaMgOUG9CAE4xRHQDgNLc_pe5A","authDomain":"apizoom-epfl.firebaseapp.com","databaseURL":"https://apizoom-epfl.firebaseio.com","projectId":"apizoom-epfl","storageBucket":"apizoom-epfl.appspot.com","messagingSenderId":"1018410191771","appId":"1:1018410191771:web:57d4bca1caa1218b8f6f3e"}},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"gatsby-starter-default","short_name":"starter","start_url":"/","background_color":"#663399","theme_color":"#663399","display":"minimal-ui","icon":"src/images/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"9f17e2b6aa4c198c9d01414a31d699a3"},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-intl/gatsby-browser.js'),
      options: {"plugins":[],"path":"/vercel/path0/packages/landing-gatsby/src/intl","languages":["en","fr"],"defaultLanguage":"en","redirect":false},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-crisp-chat/gatsby-browser.js'),
      options: {"plugins":[],"websiteId":"35589797-dda6-4e7d-80ee-44afc0b65dbe","enableDuringDevelop":false,"defer":true,"enableImprovedAccessibility":true},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
